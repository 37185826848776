@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400&display=swap');


// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$title-font-family: 'Cinzel', serif;

$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #333332;
$background-color: rgb(50, 125, 160);
$content-background-color: #eef8fd;
$content-background-color-darker: #dcf0fa;
$brand-color:      #2a7ae2;
$loader-color:     #7f919e;

$grey-color: #676766;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);


// used by spinner
$white: rgb(255,255,255);
$off-white: rgba($white, 0.2);


// Size of the content area
$content-width:    1000px;

$on-tiny:          400px;
$on-palm:          600px;
$on-laptop:        840px;

$showcase-height:   540px;


// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "custom"
;
